import styled from 'styled-components';

interface StyledCheckboxProps {
  $size: number;
  $checked: boolean;
}
interface CheckboxContainerProps {
  $size: number;
}

export const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  border: 0;
  clip: rect(0 0 0 0);
  clippath: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const Icon = styled.svg`
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const StyledCheckbox = styled.div<StyledCheckboxProps>`
  display: inline-block;

  width: ${({ $size }) => $size}px;
  height: ${({ $size }) => $size}px;

  background: ${({ $checked, theme }) =>
    $checked ? theme.core.colors.primary.green.medium : theme.core.colors.greyscale.light};
  border-radius: 4px;
  border: ${({ theme, $checked }) =>
    $checked ? `${theme.core.colors.greyscale.white} 2px solid` : `${theme.core.colors.greyscale.medium} 1px solid`};

  transition: all 150ms;
  ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px ${({ theme }) => `${theme.core.colors.primary.green.medium}4D`};
  }
  ${Icon} {
    visibility: ${({ $checked }) => ($checked ? 'visible' : 'hidden')};
  }

  &:hover {
    cursor: pointer;
  }
`;

export const CheckboxContainer = styled.div<CheckboxContainerProps>`
  display: inline-block;
  vertical-align: middle;

  height: ${({ $size }) => $size}px;
`;
