import { type Method } from 'axios';
import { methods, request } from '../lib';
import type {
  ServiceTypesConfig,
  PetsConfig,
  CategoryConfig,
  OnboardingConfig,
  NotificationConfig,
  GeneralConfig,
  TerminalConfig,
  SystemConfig,
} from '../types/config.types';
import { endpoints } from './endpoints';

export async function getConfig(): any {
  const data = await request({
    endpoint: endpoints.config.all,
    method: methods.GET as Method,
  });

  return data.data.data;
}

export async function getCategoryConfig(): Promise<CategoryConfig> {
  const data = await request({
    endpoint: endpoints.config.category,
    method: methods.GET as Method,
  });

  return data.data.data;
}

export async function getPetsConfig(): Promise<PetsConfig> {
  const data = await request({
    endpoint: endpoints.config.pets,
    method: methods.GET as Method,
  });

  return data.data.data;
}

export async function getServiceTypesConfig(): Promise<ServiceTypesConfig> {
  const data = await request({
    endpoint: endpoints.config.category,
    method: methods.GET as Method,
  });

  return data.data.data;
}

export async function getOnboardingConfigProviders(): Promise<OnboardingConfig> {
  const data = await request({
    endpoint: endpoints.config.onboarding,
    method: methods.GET as Method,
  });

  return data.data.data;
}

export async function getNotificationsConfig(): Promise<NotificationConfig> {
  const data = await request({
    endpoint: endpoints.config.notifications,
    method: methods.GET as Method,
  });

  return data.data.data;
}

export async function getGeneralConfig(): Promise<GeneralConfig> {
  const data = await request({
    endpoint: endpoints.config.generic,
    method: methods.GET as Method,
  });

  return data.data.data;
}
export async function getSystemConfig(): Promise<SystemConfig> {
  const data = await request({
    endpoint: endpoints.config.system,
    method: methods.GET as Method,
  });

  return data.data.data;
}

export async function getTerminalConfig(): Promise<TerminalConfig> {
  const data = await request({
    endpoint: endpoints.config.terminal,
    method: methods.GET as Method,
  });

  return data.data.data;
}
