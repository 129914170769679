export const REPEAT_END_OPTIONS = [
  {
    label: 'Never',
    value: null,
  },
  {
    label: 'After',
    value: {
      type: 'count',
      quantity: 12,
    },
  },
];

export const REPEAT_FREQUENCY_UNITS = [
  {
    label: 'Days',
    value: 'daily',
  },
  {
    label: 'Weeks',
    value: 'weekly',
  },
];

export const REPEAT_OPTIONS = [
  { id: 'no-repeat', data: { label: 'No repeat', frequency: null, multiplier: null } },
  { id: 'weekly', data: { label: 'Weekly', multiplier: 1, frequency: 'weekly' } },
  { id: '4-weeks', data: { label: '4 weeks', multiplier: 4, frequency: 'weekly' } },
  { id: '6-weeks', data: { label: '6 weeks', multiplier: 6, frequency: 'weekly' } },
  { id: '8-weeks', data: { label: '8 weeks', multiplier: 8, frequency: 'weekly' } },
  { id: 'custom', data: { label: 'Custom', multiplier: 1, frequency: 'weekly' } },
];
