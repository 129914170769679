export const endpoints = {
  config: {
    all: `${process.env.API_ROOT_URL}/config`,
    category: `${process.env.API_ROOT_URL}/config/categories`,
    pets: `${process.env.API_ROOT_URL}/config/pets`,
    serviceTypes: `${process.env.API_ROOT_URL}/config/service-types`,
    onboarding: `${process.env.API_ROOT_URL}/config/onboarding`,
    notifications: `${process.env.API_ROOT_URL}/config/notifications`,
    generic: `${process.env.API_ROOT_URL}/config/generic`,
    terminal: `${process.env.API_ROOT_URL}/config/terminal`,
    system: `${process.env.API_ROOT_URL}/config/system`,
  },
  clients: {
    all: (queryString?: string) => `${process.env.API_ROOT_URL}/clients${queryString ? `?${queryString}` : ''}`,
    id: (clientId: string) => `${process.env.API_ROOT_URL}/clients/${clientId}`,
    notes: {
      all: (clientId: string, queryString?: string) =>
        `${process.env.API_ROOT_URL}/clients/${clientId}/notes${queryString ? `?${queryString}` : ''}`,
      id: (clientId: string, noteId: string, queryString?: string) =>
        `${process.env.API_ROOT_URL}/clients/${clientId}/notes/${noteId}${queryString ? `?${queryString}` : ''}`,
    },
    wallet: {
      all: (clientId: string, queryString?: string) =>
        `${process.env.API_ROOT_URL}/clients/${clientId}/wallet${queryString ? `?${queryString}` : ''}`,
      id: (clientId: string, itemId: string, queryString?: string) =>
        `${process.env.API_ROOT_URL}/clients/${clientId}/wallet/${itemId}${queryString ? `?${queryString}` : ''}`,
    },
  },
  sales: {
    all: (queryString?: string) => `${process.env.API_ROOT_URL}/sales${queryString ? `?${queryString}` : ''}`,
    id: (saleId: string) => `${process.env.API_ROOT_URL}/sales/${saleId}`,
    notes: {
      all: (saleId: string, queryString?: string) =>
        `${process.env.API_ROOT_URL}/sales/${saleId}/notes${queryString ? `?${queryString}` : ''}`,
      id: (saleId: string, noteId: string, queryString?: string) =>
        `${process.env.API_ROOT_URL}/sales/${saleId}/notes/${noteId}${queryString ? `?${queryString}` : ''}`,
    },
    invoice: {
      email: (saleId: string) => `${process.env.API_ROOT_URL}/sales/${saleId}/invoice/email`,
    },
    memberships: {
      all: (queryString?: string) =>
        `${process.env.API_ROOT_URL}/sales/memberships${queryString ? `?${queryString}` : ''}`,
    },
  },
  transactions: {
    all: (queryString?: string) => `${process.env.API_ROOT_URL}/transactions${queryString ? `?${queryString}` : ''}`,
    id: (transactionId: string) => `${process.env.API_ROOT_URL}/transactions/${transactionId}`,
    refund: {
      all: (transactionId: string) => `${process.env.API_ROOT_URL}/transactions/${transactionId}/refund`,
    },
  },
  onboarding: {
    session: `${process.env.API_ROOT_URL}/onboarding/sessions`,
    sessionId: (sessionId: string) => `${process.env.API_ROOT_URL}/onboarding/sessions/${sessionId}`,
    categories: (sessionId: string) => `${process.env.API_ROOT_URL}/onboarding/sessions/${sessionId}/categories`,
    address: (sessionId: string) => `${process.env.API_ROOT_URL}/onboarding/sessions/${sessionId}/address`,
    marketing: (sessionId: string) => `${process.env.API_ROOT_URL}/onboarding/sessions/${sessionId}/marketing`,
  },
  members: {
    all: (queryString?: string) => `${process.env.API_ROOT_URL}/members${queryString ? `?${queryString}` : ''}`,
    id: (memberId: string) => `${process.env.API_ROOT_URL}/members/${memberId}`,
    shifts: (memberId: string) => `${process.env.API_ROOT_URL}/members/${memberId}/shifts`,
    schedule: {
      all: (queryString?: string) =>
        `${process.env.API_ROOT_URL}/members/schedule${queryString ? `?${queryString}` : ''}`,
    },
    timeOff: {
      all: (memberId: string) => `${process.env.API_ROOT_URL}/members/${memberId}/time-off`,
      id: (memberId: string, timeOffId: string) =>
        `${process.env.API_ROOT_URL}/members/${memberId}/time-off/${timeOffId}`,
    },
  },
  appointment: {
    all: (queryString?: string) => `${process.env.API_ROOT_URL}/appointments${queryString ? `?${queryString}` : ''}`,
    id: (appointmentId: string, queryString?: string) =>
      `${process.env.API_ROOT_URL}/appointments/${appointmentId}${queryString ? `?${queryString}` : ''}`,
    notes: {
      all: (appointmentId: string, queryString?: string) =>
        `${process.env.API_ROOT_URL}/appointments/${appointmentId}/notes${queryString ? `?${queryString}` : ''}`,
      id: (appointmentId: string, noteId: string, queryString?: string) =>
        `${process.env.API_ROOT_URL}/appointments/${appointmentId}/notes/${noteId}${queryString ? `?${queryString}` : ''}`,
    },
    payments: {
      all: (appointmentId: string) => `${process.env.API_ROOT_URL}/appointments/${appointmentId}/payments`,
    },
  },
  catalogue: {
    services: {
      all: (queryString?: string) =>
        `${process.env.API_ROOT_URL}/catalogue/services${queryString ? `?${queryString}` : ''}`,
      id: (serviceId: string) => `${process.env.API_ROOT_URL}/catalogue/services/${serviceId}`,
    },
    memberships: {
      all: (queryString?: string) =>
        `${process.env.API_ROOT_URL}/catalogue/memberships${queryString ? `?${queryString}` : ''}`,
      id: (membershipId: string) => `${process.env.API_ROOT_URL}/catalogue/memberships/${membershipId}`,
    },
    categories: {
      all: (queryString?: string) =>
        `${process.env.API_ROOT_URL}/catalogue/categories${queryString ? `?${queryString}` : ''}`,
      id: (categoryId: string) => `${process.env.API_ROOT_URL}/catalogue/categories/${categoryId}`,
    },
  },
  userByEmail: (email: string) => `${process.env.API_ROOT_URL_MAIN}/users?email=${email}`,
  users: {
    id: (userId: string) => `${process.env.API_ROOT_URL}/users/${userId}`,
    auth: {
      resets: {
        all: `${process.env.API_ROOT_URL_MAIN}/users/auth/resets`,
        id: (resetId: string) => `${process.env.API_ROOT_URL_MAIN}/users/auth/resets/${resetId}`,
      },
    },
  },
  accounts: {
    id: {
      base: (accountId: string) => `${process.env.API_ROOT_URL}/accounts/${accountId}`,
    },
  },
  workplaces: `${process.env.API_ROOT_URL}/workplaces`,
  workplace: (businessId: string) => `${process.env.API_ROOT_URL}/workplaces/${businessId}`,
  settings: {
    workplaces: {
      'closed-periods': `${process.env.API_ROOT_URL}/settings/workplaces/closed-periods`,
      'closed-periods-id': (closedPeriodId: string) =>
        `${process.env.API_ROOT_URL}/settings/workplaces/closed-periods/${closedPeriodId}`,
    },
    taxes: {
      all: `${process.env.API_ROOT_URL}/settings/taxes`,
      id: (taxId: string) => `${process.env.API_ROOT_URL}/settings/taxes/${taxId}`,
    },
  },
  notifications: {
    all: `${process.env.API_ROOT_URL}/notifications`,
    id: (notificationId: string) => `${process.env.API_ROOT_URL}/notifications/${notificationId}`,
  },
  payments: {
    all: `${process.env.API_ROOT_URL}/payments/account`,
    protection: {
      all: `${process.env.API_ROOT_URL}/payments/protection`,
      type: (protectionType: string) => `${process.env.API_ROOT_URL}/payments/protection/${protectionType}`,
    },
  },
  terminals: {
    all: `${process.env.API_ROOT_URL}/payments/terminals`,
    id: (id: string) => `${process.env.API_ROOT_URL}/payments/terminals/${id}`,
  },
};
