import React, { type ReactHTMLElement } from 'react';
import { CheckboxContainer, HiddenCheckbox, Icon, StyledCheckbox } from './checkbox.styled';

interface CheckboxProps extends ReactHTMLElement<HTMLInputElement> {
  checked: boolean;
  size: number;
  onToggle?: (toggle: boolean) => void;
}

export function Checkbox({ checked, size = 20, onToggle, ...props }: CheckboxProps) {
  const handleToggle = () => {
    onToggle && onToggle(!checked);
  };
  return (
    <CheckboxContainer $size={size} onClick={handleToggle}>
      <HiddenCheckbox checked={checked} {...props} />
      <StyledCheckbox $checked={checked} $size={size}>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </StyledCheckbox>
    </CheckboxContainer>
  );
}
