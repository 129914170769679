import styled from 'styled-components';

export const NotificationContainer = styled.div`
  position: fixed;
  bottom: 16px;

  left: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  border-radius: ${({ theme }) => theme.core.border.container.radius}px;
  border: 0.5px solid ${({ theme }) => theme.core.colors.greyscale.medium};

  padding: ${({ theme }) => theme.core.sizing[2]}px ${({ theme }) => theme.core.sizing[3]}px;

  background: ${({ theme }) => theme.core.colors.greyscale.white};

  z-index: 11;

  box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

  > span {
    display: flex;
    align-items: center;
    gap: ${({ theme }) => theme.core.sizing[2]}px;

    svg {
      fill: ${({ theme }) => theme.core.colors.primary.green.medium};
    }
  }
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 700px;
    left: calc((100% - 750px) / 2);
  }
`;
