import React, { useState, useMemo, useEffect } from 'react';
import { Calendar, luxonLocalizer, type SlotInfo, Views, type View } from 'react-big-calendar';
import { DateTime } from 'luxon';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import { type Member } from '../../../../types/member.types';
import { ColourNamesCalenderEvents } from '../../../../config/colours';
import { type CalendarBackgroundEvent, type CalendarEvent } from '../../../../types/appointments.types';
import { useGetWorkplace } from '../../../../queries/workplace.queries';
import { CalendarContainer } from './calendar.styled';
import { CustomEvent } from './custom-event';
import { ResourceContainer } from './resource-container';
import { generateCalendarTimeFormats } from './helpers/generate-calender-time-formats';
import { useCalendarDndEvents } from './hooks/use-calendar-dnd-events';
import { useCalendarParams } from './hooks/use-calendar-params';
import { useNavigateWithSearchParams } from '../../../../hooks/use-navigate-with-search-params';

const DragAndDropCalendar = withDragAndDrop(Calendar);

interface CalenderComponentProps {
  handleSelectAppointmentTime: (startTime: Date, calenderTeamMemberId?: string) => void;
  isFetchingAppointments: boolean;
  viewMode: string;
  events: CalendarEvent[];
  calendarDate: number;
  members?: Member[];
  backgroundEvents: CalendarBackgroundEvent[];
}

export function CalendarComponent({
  handleSelectAppointmentTime,
  viewMode,
  events,
  calendarDate,
  members,
  backgroundEvents,
}: CalenderComponentProps) {
  const [calendarEvents, setCalendarEvents] = useState(events);

  const { workplaceId } = useCalendarParams();

  const navigate = useNavigateWithSearchParams();

  const { data: currentWorkplace } = useGetWorkplace(workplaceId, {
    enabled: Boolean(workplaceId),
  });

  const getCalendarScrollTime = () => {
    const currentWorkplaceOpeningTime = currentWorkplace?.openingTimes.find(
      (day) => day.day === DateTime.fromISO(calendarDate).weekday - 1,
    );

    if (!currentWorkplace) {
      return undefined;
    }

    if (currentWorkplaceOpeningTime?.periods.length) {
      return DateTime.fromISO(calendarDate)
        .plus({
          seconds: currentWorkplaceOpeningTime.periods[0].startTime,
        })
        .toJSDate();
    }

    return new Date();
  };

  const { handleMoveEvent, handleResizeEvent } = useCalendarDndEvents(setCalendarEvents);

  const timeFormats = generateCalendarTimeFormats();

  const currentDate = new Date(calendarDate);

  const calendarMemberResources = useMemo(
    () =>
      members?.map((member) => ({
        id: member.id,
        title: member.firstName,
        profilePicture: member.profilePicture,
        firstName: member.firstName,
        lastName: member.lastName,
        calendarColour: member.calendarColour,
      })),
    [members],
  );

  useEffect(() => {
    setCalendarEvents(events);
  }, [events]);

  const eventPropGetter = (event: CalendarEvent) => {
    return {
      className: `${ColourNamesCalenderEvents[event.backgroundColour]}${event.isDisabled ? ' rbc-event-disabled' : ''}`,
    };
  };

  function handleSelectEvent(event: CalendarEvent) {
    if (event.isDisabled) {
      return;
    }

    if (event.eventType === 'appointment') {
      navigate(`/calendar/panel/appointment-view/${event.appointmentId}/overview`);
    }
    if (event.eventType === 'block-time') {
      navigate(`/calendar/block-time/edit/${event.id}`, undefined, { 'blt-mbr-id': event.resourceId });
    }
  }

  function handleDateClick(clickedDate: SlotInfo) {
    handleSelectAppointmentTime(clickedDate.start, clickedDate.resourceId as string);
  }

  if (getCalendarScrollTime()) {
    return (
      <CalendarContainer animate={{ opacity: 1 }} initial={{ opacity: 0 }} transition={{ duration: 0.2 }}>
        <DragAndDropCalendar
          backgroundEvents={backgroundEvents}
          components={{
            event: CustomEvent,
            resourceHeader: ResourceContainer,
          }}
          date={currentDate}
          defaultView={Views.WEEK}
          draggableAccessor={(event) => !event.isDisabled}
          eventPropGetter={eventPropGetter}
          events={calendarEvents}
          formats={timeFormats}
          localizer={luxonLocalizer(DateTime, { firstDayOfWeek: 1 })}
          onEventDrop={handleMoveEvent}
          onEventResize={handleResizeEvent}
          onNavigate={() => {}}
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleDateClick}
          onView={() => {}}
          popup
          resizable
          resizableAccessor={(event) => !event.isDisabled}
          resources={calendarMemberResources}
          scrollToTime={getCalendarScrollTime()}
          selectable
          step={15}
          timeslots={4}
          toolbar={false}
          view={(viewMode as View) ?? 'day'}
        />
      </CalendarContainer>
    );
  }
  return null;
}
