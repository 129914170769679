import React, { useMemo } from 'react';
import { ProductFruits } from 'react-product-fruits';
import { useGetUser } from '../queries/user.queries';
import { useAuthContext } from '../contexts/auth.context';

export function ProductFruitsInit() {
  const { isAuthenticated } = useAuthContext();

  const { data } = useGetUser({ enabled: Boolean(isAuthenticated) });

  const userSettings = useMemo(
    () => ({
      username: data?.id,
      firstname: data?.firstName,
      lastname: data?.lastName,
      email: data?.email,
    }),

    [data],
  );

  if (data) {
    return <ProductFruits language="en" user={userSettings} workspaceCode="wBU8mtetrwVise3f" />;
  }

  return null;
}
