import { useMemo } from 'react';
import { DateTime } from 'luxon';
import { useGetAppointments } from '../../../queries/appointment.queries';
import { type Appointment } from '../../../types/appointments.types';
import { useCalendarParams } from '../components/calendar/hooks/use-calendar-params';
import { useCalendarEventRequestParams } from './use-calendar-event-request-params';

export function useAppointmentEvents() {
  const { teamMemberId, workplaceId, calendarDate, viewMode } = useCalendarParams();
  const getSearchQueryString = useCalendarEventRequestParams();

  const { data: appointments, isLoading } = useGetAppointments(
    ['appointments', teamMemberId, workplaceId, calendarDate, viewMode],
    getSearchQueryString(),
    {
      enabled: Boolean(workplaceId),
      keepPreviousData: true,
      refetchInterval: 60000,
    },
  );

  const formattedAppointments = useMemo<Appointment[]>(() => {
    return appointments?.data.reduce((services, appointment) => {
      return [
        ...services,
        ...appointment.appointmentItems.map((item) => {
          return {
            id: item.id,
            appointmentId: appointment.id,
            title: `${appointment.pet.name} - ${appointment.pet.client?.firstName} ${appointment.pet.client?.lastName}`,
            description: item.name,
            resourceId: item.member?.id,
            start: DateTime.fromSeconds(
              DateTime.fromISO(appointment.date).toSeconds() + (item.startTime - (item.blockedTimeBefore ?? 0)),
            )
              .setZone('local')
              .toJSDate(),

            end: DateTime.fromSeconds(
              DateTime.fromISO(appointment.date).toSeconds() + (item.endTime + (item.blockedTimeAfter ?? 0)),
            )
              .setZone('local')
              .toJSDate(),
            backgroundColour: item.servicePrice?.service.serviceCategory?.colour ?? '#708090',
            status: appointment.status,
            blockedTimeAfter: item.blockedTimeAfter,
            blockedTimeBefore: item.blockedTimeBefore,
            recurrence: appointment.recurrence,
            sale: appointment.sale,
            transactions: appointment.transactions,
            eventType: 'appointment',
          };
        }),
      ];
    }, []);
  }, [appointments]);

  return { appointments: formattedAppointments, isLoading };
}
