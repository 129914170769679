import { ReactComponent as Client } from './client.svg';
import { ReactComponent as Calendar } from './calendar.svg';
import { ReactComponent as Sales } from './sales.svg';
import { ReactComponent as Team } from './team.svg';
import { ReactComponent as Settings } from './settings.svg';
import { ReactComponent as Workplace } from './workplace.svg';
import { ReactComponent as Catalogue } from './catalogue.svg';
import { ReactComponent as ActionMenu } from './action-menu.svg';
import { ReactComponent as Arrow } from './arrow.svg';
import { ReactComponent as Cat } from './cat.svg';
import { ReactComponent as Dog } from './dog.svg';
import { ReactComponent as Close } from './close.svg';
import { ReactComponent as Delete } from './delete.svg';
import { ReactComponent as Edit } from './edit.svg';
import { ReactComponent as Notification } from './notification.svg';
import { ReactComponent as Search } from './search.svg';
import { ReactComponent as Empty } from './empty.svg';
import { ReactComponent as PataLogo } from './pata-logo.svg';
import { ReactComponent as User } from './user.svg';
import { ReactComponent as Filters } from './filters.svg';
import { ReactComponent as Bold } from './bold.svg';
import { ReactComponent as Strikethrough } from './strikethrough.svg';
import { ReactComponent as Italic } from './italic.svg';
import { ReactComponent as Underline } from './underline.svg';
import { ReactComponent as Clear } from './clear.svg';
import { ReactComponent as BulletList } from './bullet-list.svg';
import { ReactComponent as OrderedList } from './ordered-list.svg';
import { ReactComponent as QuoteBlock } from './quote-block.svg';
import { ReactComponent as Undo } from './undo.svg';
import { ReactComponent as ClosedDate } from './closed-date.svg';
import { ReactComponent as Membership } from './membership.svg';
import { ReactComponent as Note } from './note.svg';
import { ReactComponent as Sort } from './sort.svg';
import { ReactComponent as Grooming } from './grooming.svg';
import { ReactComponent as DogWalking } from './dog-walking.svg';
import { ReactComponent as Daycare } from './daycare.svg';
import { ReactComponent as Boarding } from './boarding.svg';
import { ReactComponent as Breeder } from './breeder.svg';
import { ReactComponent as PetStore } from './pet-store.svg';
import { ReactComponent as Sitting } from './sitting.svg';
import { ReactComponent as Training } from './training.svg';
import { ReactComponent as LineBreak } from './line-break.svg';
import { ReactComponent as HorizontalRule } from './horizontal-rule.svg';
import { ReactComponent as Tax } from './tax.svg';
import { ReactComponent as Media } from './media.svg';
import { ReactComponent as Terminal } from './terminal.svg';
import { ReactComponent as Cash } from './cash.svg';
import { ReactComponent as Email } from './email.svg';
import { ReactComponent as TextMessage } from './text-message.svg';
import { ReactComponent as Google } from './google.svg';
import { ReactComponent as Vet } from './vet.svg';
import { ReactComponent as PetCremation } from './pet-cremation.svg';
import { ReactComponent as PetHydrotherapy } from './pet-hydrotheraphy.svg';
import { ReactComponent as PetTransportation } from './pet-transportation.svg';
import { ReactComponent as PetService } from './pet-service.svg';
import { ReactComponent as Other } from './other.svg';
import { ReactComponent as PetPhotography } from './pet-photography.svg';
import { ReactComponent as Tick } from './tick.svg';
import { ReactComponent as Pin } from './pin.svg';
import { ReactComponent as PetBehaviourist } from './pet-behaviourist.svg';
import { ReactComponent as Horse } from './horse.svg';
import { ReactComponent as Rodent } from './rodent.svg';
import { ReactComponent as Rabbit } from './rabbit.svg';
import { ReactComponent as Reptile } from './reptile.svg';
import { ReactComponent as Bird } from './bird.svg';
import { ReactComponent as Delivery } from './delivery.svg';
import { ReactComponent as CardDeclined } from './card-declined.svg';
import { ReactComponent as CalendarSuccess } from './calendar-success.svg';
import { ReactComponent as OnlineBooking } from './online-booking.svg';
import { ReactComponent as Copy } from './copy.svg';
import { ReactComponent as Repeat } from './repeat.svg';
import { ReactComponent as NoShow } from './no-show.svg';
import { ReactComponent as Complete } from './complete.svg';

export const Icons: { [key: string]: React.FC } = {
  Client,
  User,
  Calendar,
  Sales,
  Team,
  Settings,
  Catalogue,
  Workplace,
  ActionMenu,
  Arrow,
  Cat,
  Dog,
  Close,
  Delete,
  Edit,
  Notification,
  Search,
  Empty,
  PataLogo,
  Filters,
  Bold,
  Strikethrough,
  Italic,
  Underline,
  Clear,
  BulletList,
  OrderedList,
  QuoteBlock,
  Undo,
  ClosedDate,
  Membership,
  Note,
  Sort,
  Grooming,
  DogWalking,
  Boarding,
  Daycare,
  Breeder,
  PetStore,
  Sitting,
  Training,
  LineBreak,
  HorizontalRule,
  Tax,
  Media,
  Terminal,
  Cash,
  TextMessage,
  Email,
  Google,
  Vet,
  PetCremation,
  PetHydrotherapy,
  PetTransportation,
  PetService,
  Other,
  PetPhotography,
  Tick,
  Pin,
  PetBehaviourist,
  Rabbit,
  Rodent,
  Reptile,
  Bird,
  Horse,
  Delivery,
  CardDeclined,
  CalendarSuccess,
  OnlineBooking,
  Copy,
  Repeat,
  NoShow,
  Complete,
};
