import { signOut } from 'aws-amplify/auth';
import { useCallback } from 'react';
import { queryClient } from '../queries/query-client';

export function useLogout() {
  const handleLogout = useCallback(async () => {
    window.productFruits.services.destroy();

    await signOut();

    queryClient.removeQueries();

    localStorage.removeItem('clr-view');
    localStorage.removeItem('clr-date');
    localStorage.removeItem('clr-mbr-id');
    localStorage.removeItem('clr-wpl-id');
    localStorage.removeItem('accountId');
  }, []);

  return handleLogout;
}
