import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Modal } from '@pata-app/ui-modal';
import { useNavigate } from 'react-router-dom';
import { Button } from '@pata-app/ui-form';
import { type Workplace } from '../../../../types/workplace.types';
import { Image } from '../../../../components/image/image';
import { IconPlaceholder } from '../../../../components/icon-placeholder/icon-placeholder';
import { useGetCategoryConfig } from '../../../../queries/config.queries';
import { ActionsMenu } from '../../../../components/actions-menu/actions-menu';
import { Icon } from '../../../../components/icon/icon';
import { useGetAccount } from '../../../../queries/account.queries';
import { CopyButton } from '../../../../components/copy-button/copy-button';
import { NotificationBody } from '../../../../components/notification-body/notification-body';
import { usePatchWorkplace } from '../../../../queries/workplace.queries';
import { queryClient } from '../../../../queries/query-client';
import {
  WorkplaceAddress,
  WorkplaceCategory,
  WorkplaceName,
  WorkplaceTileContainer,
  ImageContainer,
  DetailsContainer,
  ListedIndicator,
  ActionsContainer,
  ActionMenuButton,
  ProfileLinksContainer,
  UnlistedMessage,
} from './online-booking.styled';

interface WorkplaceTileProps {
  workplace: Workplace;
}

export function WorkplaceTile({ workplace }: WorkplaceTileProps) {
  const { t } = useTranslation();

  const [showUnlistConfirmation, setShowUnlistConfirmation] = useState(false);
  const mediaUrl = Boolean(workplace.media.length) && workplace.media[0].path;

  const navigate = useNavigate();

  const address = workplace.locationAddress;

  const { data: accountData } = useGetAccount();

  const mutation = usePatchWorkplace();

  const { data: businessCategory } = useGetCategoryConfig({
    select: (data) => data.config.categories.find((category) => category.id === workplace.mainCategory),
  });

  const fullAddress = `${address.line1}, ${address.line2 ? `${address.line2}, ` : ''}${address.line3 ? `${address.line3}, ` : ''}${address.city ? `${address.city}, ` : ''}${address.region ? `${address.region}, ` : ''}${address.postcode ? address.postcode.toUpperCase() : ''}`;

  const handleEditProfile = () => {
    navigate(`/online-booking/profile/edit/${workplace.id}`);
  };

  const handleEditWorkplace = () => {
    navigate(`/settings/workplaces/edit/${workplace.id}`);
  };

  const handleListProfile = () => {
    if (workplace.media.length === 0) {
      navigate(`/online-booking/profile/list/${workplace.id}?section=media`);

      return;
    }
    navigate(`/online-booking/profile/list/${workplace.id}?section=settings`);
  };

  const handleUnlistProfile = () => {
    setShowUnlistConfirmation(true);
  };
  const handlePatchUnlist = () => {
    mutation.mutate(
      { id: workplace.id, businessDetails: { listingStatus: 'unlisted' } },
      {
        onSuccess: async () => {
          await queryClient.invalidateQueries('workplaces');

          setShowUnlistConfirmation(false);
        },
      },
    );
  };

  const bookingLink = `${process.env.WEB_BASE_URL}/booking/${accountData?.id}/${workplace.id}/pet`;
  const profileLink = `${process.env.WEB_BASE_URL}/p/${workplace.slug}`;

  const handlePreviewProfile = () => {
    window.open(`${profileLink}?preview=${workplace.id}`, '_blank');
  };

  return (
    <>
      <WorkplaceTileContainer to={`/online-booking/profile/edit/${workplace.id}`}>
        <ImageContainer>
          {workplace.listingStatus === 'listed' && <ListedIndicator>Listed</ListedIndicator>}
          {Boolean(mediaUrl) && (
            <Image alt={workplace.name} src={`${process.env.CDN_BASE_URL_USER_CONTENT}/${mediaUrl}`} />
          )}
          <IconPlaceholder icon="Workplace" size="36px" />
          {workplace.listingStatus !== 'listed' && (
            <UnlistedMessage>
              <span>Profile not listed</span>
            </UnlistedMessage>
          )}
        </ImageContainer>
        <DetailsContainer>
          <WorkplaceName>{workplace.name || '-'}</WorkplaceName>
          {Boolean(businessCategory) && <WorkplaceCategory>{businessCategory?.name}</WorkplaceCategory>}
          <WorkplaceAddress>{fullAddress || '-'}</WorkplaceAddress>
        </DetailsContainer>

        {workplace.listingStatus === 'listed' && (
          <ProfileLinksContainer>
            <h4>Marketplace links</h4>
            <div>
              <span>
                <a href={`${process.env.WEB_BASE_URL}/p/${workplace.slug}`} rel="noreferrer" target="_blank">
                  Profile link
                </a>

                <CopyButton copyText={profileLink} />
              </span>
              <span>
                <a
                  href={`${process.env.WEB_BASE_URL}/booking/${accountData?.id}/${workplace.id}/pet`}
                  rel="noreferrer"
                  target="_blank"
                >
                  Booking link
                </a>
                <CopyButton copyText={bookingLink} />
              </span>
            </div>
          </ProfileLinksContainer>
        )}

        <ActionsContainer>
          <Button buttonType="secondary" onClick={handlePreviewProfile}>
            Preview
          </Button>
          <ActionsMenu
            alignment="right"
            menuOffset={40}
            menuOptions={[
              {
                label: 'List profile',
                id: 'list_profile',
                onClick: handleListProfile,
                hide: workplace.listingStatus === 'listed',
              },
              {
                label: 'Unlist profile',
                id: 'unlist_profile',
                onClick: handleUnlistProfile,
                hide: workplace.listingStatus !== 'listed',
              },
              {
                label: 'Edit profile',
                id: 'edit_online_booking',
                onClick: handleEditProfile,
                hide: workplace.listingStatus !== 'listed',
              },
              {
                label: 'Edit workplace settings',
                id: 'edit_workplace',
                onClick: handleEditWorkplace,
              },
            ]}
          >
            <ActionMenuButton>
              Actions
              <Icon type="Arrow" size="16px" />
            </ActionMenuButton>
          </ActionsMenu>
        </ActionsContainer>
      </WorkplaceTileContainer>

      <Modal
        actions={[
          {
            label: t('cancel', { ns: 'common' }),
            onClick: () => setShowUnlistConfirmation(false),
            buttonType: 'secondary',
          },
          {
            label: t('unlist', { ns: 'common' }),
            onClick: handlePatchUnlist,
            isLoading: mutation.isLoading,
            buttonType: 'danger',
          },
        ]}
        handleToggleModal={setShowUnlistConfirmation}
        isOpen={showUnlistConfirmation}
        modalTitle={'Unlist profile'}
        sizing={{
          width: '300px',
          maxWidth: '200px',
          maxHeight: '300px',
          height: '350px',
          minHeight: '300px',
        }}
      >
        <NotificationBody
          description={'Unlisting your workplace will mean your profile will no longer be accessible.'}
          title={`Are you sure you want to unlist ${workplace.name} from the marketplace?`}
        />
      </Modal>
    </>
  );
}
