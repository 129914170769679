import React, { useMemo, useState } from 'react';
import Select from 'react-select';
import { DateTime } from 'luxon';
import { DatePicker, TileSelect, selectStyles } from '@pata-app/ui-form';
import { MobilePopUp } from '@pata-app/ui-pop-outs';
import { useCalendarParams } from '../hooks/use-calendar-params';
import { useCalendarFilters } from '../hooks/use-calendar-filters';
import { ResourceTile } from '../../../../../components/Resource-tile/resource-tile';
import { Icon } from '../../../../../components/icon/icon';
import {
  ActionContainer,
  CalendarPopupContainer,
  CalendarViewButton,
  FiltersButton,
  MobileHeaderContainer,
} from './calendar-header.styled';
import { ViewModeSelect } from './view-mode-select';
import { ActionsMenu } from '../../../../../components/actions-menu/actions-menu';
import { useNavigateWithSearchParams } from '../../../../../hooks/use-navigate-with-search-params';

export function CalendarHeaderMobile() {
  const navigate = useNavigateWithSearchParams();

  const [popupSelected, setPopupSelected] = useState<string | null>(null);

  const { teamMemberId, workplaceId, calendarDate, navigateDate, getDateDisplay, viewMode } = useCalendarParams();

  const { workplaceOptions, handleMemberSelect, handleWorkplaceSelect, workplaceMembers } = useCalendarFilters();

  function handleSelectCalendar() {
    setPopupSelected('calendar');
  }

  function handleSelectFilters() {
    setPopupSelected('filters');
  }

  function closeModal() {
    setPopupSelected(null);
  }

  function handleCalendarDateChange(date: Date) {
    const formattedDate = DateTime.fromJSDate(date).toISODate();

    navigateDate(formattedDate);
    setPopupSelected(null);
  }

  const titleSelectMemberOptions = useMemo(
    () =>
      workplaceMembers?.reduce(
        (options, teamMember) => {
          return [
            ...options,
            {
              id: teamMember.id,
              title: teamMember.firstName,
              description: teamMember.jobTitle,
              icon: teamMember.profilePicture,
              placeholderIcon: 'Member',
            },
          ];
        },
        [
          {
            id: '',
            title: 'All',
            description: 'Select all team members',
            icon: '',
            placeholderIcon: 'Member',
          },
        ],
      ),
    [workplaceMembers],
  );

  function handleAddAppointment() {
    navigate('/calendar/appointment');
  }
  function handleAddBlockTime() {
    navigate('/calendar/block-time');
  }

  const actionMenuOptions = [
    {
      label: 'Add Appointment',
      id: 'add_appointment',
      onClick: handleAddAppointment,
    },
    {
      label: 'Add Block Time',
      id: 'add_block_time',
      onClick: handleAddBlockTime,
    },
  ];

  return (
    <>
      <MobileHeaderContainer>
        <CalendarViewButton onClick={handleSelectCalendar}>
          {getDateDisplay()}

          <Icon size="18px" type="Arrow" />
        </CalendarViewButton>
        <FiltersButton onClick={handleSelectFilters}>
          <Icon type="Filters" />
        </FiltersButton>

        <ActionContainer $viewMode={viewMode}>
          <ActionsMenu alignment="right" buttonText="Add" menuOptions={actionMenuOptions} />
        </ActionContainer>
      </MobileHeaderContainer>

      <MobilePopUp
        fullHeight={popupSelected === 'filters'}
        handleTogglePopUp={closeModal}
        isOpen={Boolean(popupSelected)}
        title="Navigate Calender"
      >
        {popupSelected === 'calendar' && (
          <CalendarPopupContainer>
            <ViewModeSelect />
            <DatePicker date={new Date(calendarDate)} onBlur={() => {}} onDateChange={handleCalendarDateChange} />
          </CalendarPopupContainer>
        )}
        {popupSelected === 'filters' && (
          <div>
            <Select
              menuPlacement="auto"
              onChange={(event) => {
                if (event?.value) {
                  handleWorkplaceSelect(event.value);
                }
              }}
              options={workplaceOptions}
              placeholder="Select a workplace"
              styles={selectStyles}
              value={workplaceOptions?.find((option) => option.value === workplaceId)}
            />
            <TileSelect
              maxWidth={200}
              onChange={(changeValue) => {
                handleMemberSelect(changeValue[0]);
              }}
              options={titleSelectMemberOptions}
              tileComponent={ResourceTile}
              value={[teamMemberId ?? '']}
            />
          </div>
        )}
      </MobilePopUp>
    </>
  );
}
