import { type Service } from './catalogue.types';
import { type Pet } from './client.types';
import { type SearchCriteria, type RelationshipResource, type Note } from './common.types';
import { type Member } from './member.types';
import { Discount, Sale, TransactionItem } from './sales.types';

export interface ServicePrice {
  service: Service;
  id: string;
  name: string;
}

export interface AppointmentItem {
  id?: string;
  price: number;
  startTime: number;
  endTime: number;
  blockedTimeBefore: number;
  blockedTimeAfter: number;
  priceType: string;
  itemType: string;
  member: Member;
  name?: string;
  servicePrice?: ServicePrice;
  package?: RelationshipResource;
  addOn?: RelationshipResource;
  discount?: Discount;
}

export interface RepeatingPayment {
  daysBefore: number;
}
export interface RepeatingEnd {
  type: 'count';
  quantity: number;
}
export interface AppointmentRepeating {
  frequency: 'weekly' | 'daily';
  multiplier: number;
  weekDays: number[];
  recurrenceEnd: RepeatingEnd | null;
  payment: RepeatingPayment | null;
}

export interface Appointment {
  workplace: RelationshipResource;
  pet: Partial<Pet>;
  date: string;
  appointmentItems: AppointmentItem[];
  id?: string;
  status?: AppointmentStatus;
  sale: {
    status: string;
  } | null;
  transactions: TransactionItem[];
  recurrence?: AppointmentRepeating;
}

export interface AppointmentSearch {
  data: Appointment[];
  searchCriteria: SearchCriteria;
  datasetTotal: number;
  resultsetTotal: number;
}

export interface AppointmentNotesSearch {
  data: Note[];
  searchCriteria: SearchCriteria;
  datasetTotal: number;
  resultsetTotal: number;
}

export enum CalendarViews {
  WEEK = 'week',
  WORKING_WEEK = 'working_week',
  DAY = 'day',
}

export enum AppointmentStatus {
  BOOKED = 'booked',
  ARRIVED = 'arrived',
  STARTED = 'started',
  FINISHED = 'finished',
  NO_SHOW = 'no_show',
  CANCELLED = 'cancelled',
}

export interface CalendarEvent {
  id: string;
  appointmentId: string;
  backgroundColour: string;
  title: string;
  description: string;
  resourceId: string;
  start: string;
  end: string;
  status: AppointmentStatus;
  isDisabled?: boolean;
  eventType?: string;
  blockedTimeBefore: number | null;
  blockedTimeAfter: number | null;
  sale: Sale;
  transactions: TransactionItem[];
  recurrence: any;
}

export interface CalendarBackgroundEvent {}

export interface BlockTime {
  id: string;
  startDate: string;
  endDate: string;
  startTime: number;
  endTime: number;
  timeOffType: string;
  reason: string;
  memberId: string;
  title?: string;
  allowBookings: boolean;
}
