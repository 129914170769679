import React from 'react';
import { FormField, FormLayout, FormSection, Toggle, UnitInput } from '@pata-app/ui-form';
import { useTranslation } from 'react-i18next';
import { Controller, type UseFormReturn } from 'react-hook-form';
import { FormContainer } from '../../../../../components/layouts/app-layout/components/form-container/form-container';
import { type SettingsSection } from '../../../../../types/workplace.types';
import { TextEditor } from '../../../../../components/text-editor/text-editor';
import { FieldLabel } from '../../../../../components/feild-label/field-label';
import { useGetAccount } from '../../../../../queries/account.queries';
import { ErrorMessage } from '../../../../../components/error-message/error-message';
import { LinkLabel, MarketplaceInformationPanel } from './settings.styled';

interface SettingsProps {
  form: UseFormReturn<SettingsSection>;
  hideListingStatus?: boolean;
  isEdit?: boolean;
  workplaceId?: string;
  slug?: string;
}

export function Settings({ form, hideListingStatus }: SettingsProps) {
  const { t } = useTranslation('workplaces');

  const {
    formState: { errors },
    control,
  } = form;

  return (
    <FormContainer>
      <FormSection title={t('general_settings')}>
        <FormLayout columnConfig={{ m: 2 }}>
          <FormField error={errors.acceptNewClients?.message} label="">
            <Controller
              control={control}
              name="acceptNewClients"
              render={({ field }) => {
                return (
                  <div style={{ height: '45px', display: 'flex', alignItems: 'center' }}>
                    <Toggle {...field} checked={field.value as boolean} label={t('accept_new_clients')} />
                  </div>
                );
              }}
            />
          </FormField>

          {!hideListingStatus && (
            <FormField error={errors.acceptNewClients?.message} label="">
              <Controller
                control={control}
                name="listingStatus"
                render={({ field }) => {
                  return (
                    <div style={{ height: '45px', display: 'flex', alignItems: 'center' }}>
                      <Toggle
                        {...field}
                        checked={field.value === 'listed'}
                        label={t('listing_status')}
                        onChange={(event) => {}}
                      />
                    </div>
                  );
                }}
              />
            </FormField>
          )}
        </FormLayout>
      </FormSection>

      <FormSection description={t('agreement_settings_description')} title={t('agreement_settings')}>
        <FieldLabel label={t('agreement_body')} />
        <Controller
          control={control}
          name="termsAndConditions"
          render={({ field }) => {
            return <TextEditor {...field} />;
          }}
          rules={{
            maxLength: {
              value: 10000,
              message: 'Terms & Conditions are too long, reduce the character count',
            },
          }}
        />
        <ErrorMessage>{errors.termsAndConditions?.message}</ErrorMessage>
      </FormSection>

      {/* <FormSection title={t('travel_settings')}>
        <FormLayout columnConfig={{ m: 2 }}>
          <FormField
            description="If your workplace offers services remotely specify a travel distance radius from your location."
            error={errors.travelDistance?.message}
            label={t('travel_distance')}
          >
            <Controller
              control={control}
              name="travelDistance"
              render={({ field }) => {
                return (
                  <UnitInput
                    {...field}
                    hasError={Boolean(errors.travelDistance)}
                    onChange={(updatedValue) => {
                      field.onChange(parseInt(updatedValue.target.value));
                    }}
                    onInput={(event: React.ChangeEvent<HTMLInputElement>) => {
                      event.target.value = event.target.value.replace(/[^0-9]/g, '');
                    }}
                    placeholder="Enter your Travel Distance"
                    suffix={t('miles')}
                    type="number"
                    value={field.value ? field.value : null}
                  />
                );
              }}
              rules={{
                validate: (value: number) => {
                  return value && value > 100 ? 'Maximum Travel Distance is 100 miles' : undefined;
                },
              }}
            />
          </FormField>
        </FormLayout>
      </FormSection> */}
    </FormContainer>
  );
}
