import { Modal } from '@pata-app/ui-modal';
import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { type TimeOff } from '../../../../types/member.types';
import { useNavigateWithSearchParams } from '../../../../hooks/use-navigate-with-search-params';
import { useDeleteTimeOff, useGetTimeOff, usePatchTimeOff, usePostTimeOff } from '../../../../queries/member.queries';
import { Loader } from '../../../../components/loader/loader';
import { queryClient } from '../../../../queries/query-client';
import { AddEditBlockTimeForm } from './add-edit-block-time-form';
import { BlockTime } from '../../../../types/appointments.types';

export function AddEditBlockTime() {
  const { id: blockTimeId } = useParams();

  const { t } = useTranslation();

  const navigate = useNavigateWithSearchParams();

  const postTimeOffMutation = usePostTimeOff();
  const patchTimeOffMutation = usePatchTimeOff();
  const deleteTimeOffMutation = useDeleteTimeOff();

  const [searchParams] = useSearchParams();


  const isEdit = Boolean(blockTimeId);

  const memberId = searchParams.get('blt-mbr-id');
  const startDate = searchParams.get('blt-start-date');
  const startTime = searchParams.get('blt-start-time');

  const { data, isLoading } = useGetTimeOff(memberId, blockTimeId);

  const handleCloseModal = () => {
    const element = document.getElementById('root');

    element?.classList.remove('modal-open');

    navigate('/calendar', undefined, undefined, ['blt-mbr-id', 'blt-start-date', 'blt-start-time']);
  };

  const form = useForm<BlockTime>({
    defaultValues: {
      typeOffType: undefined,
      startDate: startDate ?? undefined,
      endDate: startDate ?? undefined,
      startTime: startTime ? Number(startTime) : undefined,
      endTime: startTime ? Number(startTime) + 3600 : undefined,
      description: undefined,
      memberId: memberId ?? undefined,
      allowBookings: false,
      isApproved: false,
      title: undefined,
    },
  });

  const {
    getValues,
    trigger,
    formState: { isValid },
    reset,
  } = form;

  useEffect(() => {
    if (data) {
      const editData = {
        memberId,
        startDate: data.startDate,
        endDate: data.endDate,
        endTime: data.endTime,
        startTime: data.startTime,
        title: data.title,
        description: data.description,
        allowBookings: data.allowBookings,
        timeOffType: {
          id: data.timeOffType,
        },
      };
      reset(editData);
    }
  }, [data, reset]);

  const onSuccess = () => {
    void queryClient.invalidateQueries(['schedule']);
    void queryClient.invalidateQueries(['timeOff', memberId, blockTimeId]);

    handleCloseModal();
  };

  const handleSaveBlockTime = async () => {
    await trigger();

    if (!isValid) {
      return;
    }
    const formValues = getValues();

    if (blockTimeId) {
      patchTimeOffMutation.mutate(
        {
          timeOffId: blockTimeId,
          memberId: formValues.memberId,
          timeOffDetails: {
            ...formValues,
            timeOffType: formValues.timeOffType.id,
            memberId: undefined,
            endDate: formValues.startDate,
          },
        },
        { onSuccess },
      );
    } else {
      postTimeOffMutation.mutate(
        {
          timeOffId: blockTimeId,
          memberId: formValues.memberId,
          timeOffDetails: {
            ...formValues,
            timeOffType: formValues.timeOffType.id,
            memberId: undefined,
            endDate: formValues.startDate,
          },
        },
        { onSuccess },
      );
    }
  };

  const handleDeleteTimeOff = useCallback(() => {
    deleteTimeOffMutation.mutate(
      { memberId, timeOffId: blockTimeId },
      {
        onSuccess: () => {
          void queryClient.invalidateQueries(['schedule']);

          handleCloseModal();
        },
      },
    );
  }, [deleteTimeOffMutation, memberId, blockTimeId]);

  const modalActions = useMemo(
    () => [
      {
        label: t('delete', { ns: 'common' }),
        onClick: handleDeleteTimeOff,
        buttonType: 'danger',
        disabled: postTimeOffMutation.isLoading || deleteTimeOffMutation.isLoading,
        hideAction: !isEdit,
        isLoading: deleteTimeOffMutation.isLoading,
      },
      {
        label: t('cancel', { ns: 'common' }),
        onClick: handleCloseModal,
        buttonType: 'secondary',
        disabled: patchTimeOffMutation.isLoading || postTimeOffMutation.isLoading || deleteTimeOffMutation.isLoading,
      },
      {
        label: t('save', { ns: 'common' }),
        onClick: handleSaveBlockTime,
        buttonType: 'cta',
        disabled: deleteTimeOffMutation.isLoading,
        isLoading: patchTimeOffMutation.isLoading || postTimeOffMutation.isLoading,
      },
    ],
    [handleCloseModal, t, patchTimeOffMutation, postTimeOffMutation],
  );

  // if (isLoading) {
  //   return <Loader />;
  // }

  return (
    <Modal
      actions={modalActions}
      handleToggleModal={handleCloseModal}
      isOpen
      modalTitle={blockTimeId ? 'Edit Block Time' : 'Add Block Time'}
      sizing={{
        width: '600px',
        maxWidth: '600px',
        minWidth: '600px',
        height: '800px',
        maxHeight: `calc(100vh - 50px)`,
      }}
    >
      {isLoading ? <Loader /> : <AddEditBlockTimeForm form={form} />}
    </Modal>
  );
}
