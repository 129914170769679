import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { CalendarContent } from './calendar-content';

export function Calendar() {
  return (
    <Routes>
      <Route element={<CalendarContent />} path="/:addType" />
      <Route element={<CalendarContent />} path="/:addType/:action" />
      <Route element={<CalendarContent />} path="/:addType/:action/:id" />
      <Route element={<CalendarContent />} path="/*" />
    </Routes>
  );
}

export default Calendar;
