import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SectionHeader } from '../../components/layouts/app-layout/components/section-header/section-header';
import { useGetWorkplaces } from '../../queries/workplace.queries';
import { RestrictedColumnWidth } from '../../components/layouts/app-layout/components/restricted-column-width/restricted-column-width';
import { EmptyState } from '../../components/empty-state/empty-state';
import { SectionContainer } from '../../components/layouts/app-layout/components/section-container/section-container';
import { Loader } from '../../components/loader/loader';
import { WorkplaceTilesContainer } from './online-booking.styled';
import { WorkplaceTile } from './components/online-booking/online-booking-tile';
import { AddEditWorkplace } from './edit-online-profile/edit-online-profile';

export function OnlineBooking(): JSX.Element {
  const { data: workplaces, isLoading } = useGetWorkplaces();

  const navigate = useNavigate();

  const { t } = useTranslation('workplaces');

  function handleAddWorkplace() {
    navigate('/settings/workplaces/add');
  }

  return (
    <>
      <RestrictedColumnWidth>
        <SectionContainer>
          <SectionHeader description={t('online_booking_description')} title={t('online_booking')} />
          {Boolean(isLoading) ? (
            <Loader />
          ) : workplaces?.length ? (
            <WorkplaceTilesContainer>
              {workplaces?.map((workplace) => <WorkplaceTile workplace={workplace} key={workplace.id} />)}
            </WorkplaceTilesContainer>
          ) : (
            <EmptyState
              description={t('online_booking_empty_state_description')}
              icon="Workplace"
              title={t('online_booking_empty_state_title')}
              onClick={handleAddWorkplace}
            />
          )}
        </SectionContainer>
      </RestrictedColumnWidth>

      <AddEditWorkplace />
    </>
  );
}
