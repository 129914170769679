import { createSearchParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { useCalendarParams } from '../components/calendar/hooks/use-calendar-params';

export function useCalendarEventRequestParams() {
  const { teamMemberId, workplaceId, calendarDate } = useCalendarParams();

  function getSearchQueryString() {
    return createSearchParams({
      'workplace-id': workplaceId,
      'member-id': teamMemberId,
      'start-date': DateTime.fromISO(calendarDate).minus({ days: 6 }),
      'end-date': DateTime.fromISO(calendarDate).plus({ days: 6 }),
      status: 'booked,finished,arrived,started,no_show',
    });
  }

  return getSearchQueryString;
}
