import React from 'react';
import { useParams } from 'react-router-dom';
import { Button } from '@pata-app/ui-form';
import { Icon } from '../../../../components/icon/icon';
import { useNavigateWithSearchParams } from '../../../../hooks/use-navigate-with-search-params';
import { NotificationContainer } from './new-calendar-item-notification.styled';

export function NewCalendarItemNotification() {
  const { addType, id } = useParams();

  const navigate = useNavigateWithSearchParams();

  const handleCancelAddItem = () => {
    navigate('/calendar');
  };

  if ((addType === 'block-time' || addType === 'appointment') && !id) {
    return (
      <NotificationContainer>
        <span>
          <Icon size="32px" type={addType === 'block-time' ? 'ClosedDate' : 'Calendar'} />
          {addType === 'block-time'
            ? 'Select a time on the calendar to add a block time'
            : 'Select a time on the calendar to add an appointment'}
        </span>
        <Button buttonType="secondary" onClick={handleCancelAddItem}>
          Cancel
        </Button>
      </NotificationContainer>
    );
  }

  return null;
}
