import React from 'react';
import { type EventProps } from 'react-big-calendar';
import { useTranslation } from 'react-i18next';
import styled, { useTheme } from 'styled-components';
import { formatSeconds } from '../../../../lib/helpers/format-seconds';
import { type AppointmentStatus, type CalendarEvent } from '../../../../types/appointments.types';
import { BlockedTimeContainer, CustomEventContainer, IconsContainer } from './custom-event.styled';
import { removeDuplicateWords } from '../../../../lib/helpers/remove-duplicated-services';
import { Icon } from '../../../../components/icon/icon';
import { Tooltip } from 'react-tooltip';
import { TooltipIcon } from '../../../../components/tooltip-icon/tooltip-icon';
import { REPEAT_FREQUENCY_UNITS } from '../../../../components/drawers/add-edit-appointment/components/appointment-repeating/appointment-repeating.config';
import { formatCurrency } from '../../../../lib/helpers/format-currency';

export const AppointmentStatusIndicatorContainer = styled.span`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-end;

  top: 4px;

  height: 18px;

  padding: 0 ${({ theme }) => theme.core.sizing[1]}px;

  font-size: ${({ theme }) => theme.core.fontSizes.s}px;

  color: ${({ theme }) => theme.core.colors.greyscale.white};
  border: 1px solid ${({ theme }) => theme.core.colors.greyscale.white};

  border-radius: 9px;
`;

interface AppointmentStatusIndicatorProps {
  status: AppointmentStatus;
}

const STATUS_ICON_LOOKUP = {
  booked: 'Calendar',
  arrived: 'Calendar',
  started: 'Calendar',
  finished: 'Complete',
  completed: 'Complete',
  no_show: 'NoShow',
  cancelled: 'ClosedDate',
};

export function AppointmentStatusIndicator({ status }: AppointmentStatusIndicatorProps) {
  const { t } = useTranslation('common');

  return <AppointmentStatusIndicatorContainer>{t(status)}</AppointmentStatusIndicatorContainer>;
}

export function CustomEvent({ event }: EventProps<CalendarEvent>) {
  const { t } = useTranslation('common');

  return (
    <CustomEventContainer>
      <strong>{event.title}</strong>
      <span>{removeDuplicateWords(event.description)}</span>
      {Boolean(event.blockedTimeBefore || event.blockedTimeAfter) && (
        <BlockedTimeContainer>
          <span>Blocked time: </span>
          {Boolean(event.blockedTimeBefore) && (
            <span>{`${formatSeconds(event.blockedTimeBefore, undefined, true)} before`}</span>
          )}
          {Boolean(event.blockedTimeAfter && event.blockedTimeBefore) && <span>|</span>}
          {Boolean(event.blockedTimeAfter) && (
            <span>{`${formatSeconds(event.blockedTimeAfter, undefined, true)} after`}</span>
          )}
        </BlockedTimeContainer>
      )}
      <IconsContainer>
        {Boolean(event.status) && (
          <TooltipIcon icon={STATUS_ICON_LOOKUP[event.status]} id="calendar-tooltip" tooltipText={t(event.status)} />
        )}
        {(Boolean(event.sale) || Boolean(event.transactions?.length)) && (
          <TooltipIcon
            tooltipText={
              event.sale
                ? `Sale No. ${event.sale?.number} - ${t(event.sale.status, { ns: 'sales' })}`
                : `Up front payment ${formatCurrency(event.transactions[0].amount)} - ${event.transactions[0].collectedAt ? 'paid' : 'pending'}`
            }
            id="calendar-tooltip"
            icon="Cash"
          />
        )}
        {Boolean(event.recurrence) && (
          <TooltipIcon
            tooltipText={`Every ${event.recurrence.multiplier > 1 ? event.recurrence.multiplier : ''} ${REPEAT_FREQUENCY_UNITS.find((unit) => unit.value === event.recurrence.frequency)?.label.slice(0, -1)}${event.recurrence.multiplier > 1 ? 's' : ''} `}
            id="calendar-tooltip"
            icon="Repeat"
          />
        )}
      </IconsContainer>

      {/* {Boolean(event.status) && <AppointmentStatusIndicator status={event.status} />} */}
    </CustomEventContainer>
  );
}
