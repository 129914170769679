import React from 'react';
import { Tooltip } from 'react-tooltip';
import { useTheme } from 'styled-components';
import { Icon } from '../icon/icon';
import { TooltipIconContainer } from './tooltip-icon.styled';

interface TooltipIconProps {
  icon: string;
  tooltipText: string;
  id: string;
  placement?: 'top' | 'left' | 'right' | 'bottom';
}

export function TooltipIcon({ icon, tooltipText, id, placement = 'top' }: TooltipIconProps) {
  return (
    <TooltipIconContainer
      data-tooltip-content={tooltipText}
      //   data-tooltip-position-strategy="fixed"
      data-tooltip-id={id}
      data-tooltip-place={placement}
    >
      <Icon type={icon} size="18px" />
    </TooltipIconContainer>
  );
}
