import React from 'react';
import Select from 'react-select';
import { selectStyles } from '@pata-app/ui-form';
import { viewMenuOptions } from '../../../calendar.config';
import { useCalendarParams } from '../hooks/use-calendar-params';
import { useCalendarFilters } from '../hooks/use-calendar-filters';
import { ActionsMenu } from '../../../../../components/actions-menu/actions-menu';
import { useNavigateWithSearchParams } from '../../../../../hooks/use-navigate-with-search-params';
import { DesktopHeaderContainer, FiltersContainer, RightSideActions } from './calendar-header.styled';
import { DateNavigator } from './date-navigator';

export function CalendarHeaderDesktop() {
  const { teamMemberId, workplaceId, viewMode } = useCalendarParams();

  const navigate = useNavigateWithSearchParams();

  const { workplaceOptions, memberOptionsWithAll, handleMemberSelect, handleViewModeSelect, handleWorkplaceSelect } =
    useCalendarFilters();

  function handleAddAppointment() {
    navigate('/calendar/appointment');
  }
  function handleAddBlockTime() {
    navigate('/calendar/block-time');
  }

  const actionMenuOptions = [
    {
      label: 'Add Appointment',
      id: 'add_appointment',
      onClick: handleAddAppointment,
    },
    {
      label: 'Add Block Time',
      id: 'add_block_time',
      onClick: handleAddBlockTime,
    },
  ];

  return (
    <DesktopHeaderContainer>
      <FiltersContainer>
        <Select
          isSearchable={false}
          menuPlacement="auto"
          onChange={(event) => {
            if (event?.value) {
              handleWorkplaceSelect(event.value);
            }
          }}
          options={workplaceOptions}
          placeholder="Select a workplace"
          styles={{
            ...selectStyles,
            container: (base) => ({
              ...base,
              width: 200,
            }),
          }}
          value={workplaceOptions?.find((option) => option.value === workplaceId)}
        />
        <Select
          isSearchable={false}
          menuPlacement="auto"
          onChange={(event) => {
            if (event?.value || event?.value === '') {
              handleMemberSelect(event.value);
            }
          }}
          options={memberOptionsWithAll}
          placeholder="Select a team member"
          styles={{
            ...selectStyles,
            container: (base) => ({
              ...base,
              width: 150,
            }),
          }}
          value={memberOptionsWithAll?.find((option) => option.value === teamMemberId)}
        />
      </FiltersContainer>
      <DateNavigator />

      <RightSideActions>
        <Select
          isSearchable={false}
          menuPlacement="auto"
          onChange={(event) => {
            if (event?.value) {
              handleViewModeSelect(event.value);
            }
          }}
          options={viewMenuOptions}
          placeholder="Select view option"
          styles={selectStyles}
          value={viewMenuOptions.find((option) => option.value === viewMode)}
        />
        <ActionsMenu alignment="right" buttonText="Add" menuOptions={actionMenuOptions} />
      </RightSideActions>
    </DesktopHeaderContainer>
  );
}
